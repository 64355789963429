import React, { Component } from 'react';
import {
  CloudUploadOutlined,
  ZoomInOutlined,
  AudioOutlined,
} from '@ant-design/icons';
import { encode } from 'html-entities';
import CKEditor4 from 'ckeditor4-react-advanced';
import { STORAGE_PATH } from '../../../constants/default-value.const';
import { measureText, sleep } from '../../../utils/helper.utils';
import { storagePath, uploadPhoto } from '../../../utils/firebase.utils';
import { Col, Form, Modal, Row } from 'antd';
import { MathJaxPreview } from '../../mathjax-preview/mathjax-preview.component';
import {
  EditorContainer,
  OptionIcon,
  TextEditorPreview,
  WrapIcon,
} from './text-editor.styles';
import { messageWarn } from '../../../utils/message.utils';

let config = {
  extraPlugins: 'justify,font,colorbutton,language',
  /* toolbarGroups: [
    { name: 'document',    groups: [ 'mode', 'document', 'doctools' ] },
    { name: 'clipboard',   groups: [ 'clipboard', 'undo' ] },
    { name: 'editing',     groups: [ 'find', 'selection', 'spellchecker' ] },
    { name: 'forms' },
    { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
    { name: 'paragraph',   groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ] },
    { name: 'links' },
    { name: 'insert' },
    { name: 'styles' },
    { name: 'colors' },
    { name: 'tools' },
    { name: 'others' },
    { name: 'about' }
  ], */
  toolbar: [
    ['Maximize','Source'],
    ['Image', 'Table', 'HorizontalRule', 'Iframe'],
    ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
    [ 'Bold', 'Italic', 'Underline', 'Strike'],
    ['Subscript', 'Superscript'],['TextColor', 'BGColor'],
    ['Find', 'Replace', 'SelectAll'],['NumberedList', 'BulletedList', 'Outdent', 'Indent'],
    ['Link', 'Unlink'],
    ['CreateDiv', 'ShowBlocks', 'CopyFormatting'],
  ],
};

/* toolbar : [
  ['Source'],
  ['Find', 'Replace', 'SelectAll'],
  ['Undo','Redo'],
  ['NumberedList', 'BulletedList', 'Outdent', 'Indent'],
  ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock',],
  ['Link', 'Unlink' ,'Image', 'Table', 'HorizontalRule', 'Iframe'],
  '/',
  ['Bold', 'Italic', 'Underline', 'StrikeThrough' , '-', 'Subscript', 'Superscript',
  'TextColor', 'BGColor'],
  ['Styles', 'Format', 'Font', 'FontSize'],
  ['CreateDiv','ShowBlocks'],
  ], */

export class TextEditorForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      content: '',
      isDone: false,
      measureText: 0,
      forceUpdate: 0,
    };

    this.myRef = React.createRef();
    this.myRefAudio = React.createRef();
  }

  componentDidMount() {
    this.setState({ content: this.props.value });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.isDone && prevProps.value) {
      this.setState({ isDone: true, content: prevProps.value });
    }
  }

  onChangeValue = (evt) => {
    const { form, name, onChange } = this.props;
    var html = evt.editor.getData();
    form.setFieldsValue({ [name]: html });

    this.setState({ content: html });
    if (onChange) onChange(html);
  };

  upload = async (e) => {
    if (e.target.files[0]) {
      if (e.target.files[0].size / 10240 > 25000) {
        messageWarn('Image size limit to 250KB');
        return;
      }

      const { path } = await uploadPhoto(
        e.target.files[0],
        STORAGE_PATH.EDITOR,
        () => {
          this.setState((state) => ({ forceUpdate: state.forceUpdate + 1 }));
          // this.forceUpdate();
        }
      );

      let link = storagePath(path);
      const { name, form } = this.props;

      const img = `<p><img alt="" src="${link}" width="100%" height="100%"/></p>`;
      await sleep(2000);
      this.setState({ content: `${this.state.content}${img}` });
      form.setFieldsValue({ [name]: `${this.state.content}${img}` });
    }
  };

  uploadAudio = async (e) => {
    if (e.target.files[0]) {
      // if (e.target.files[0].size / 1024 > 250) {
      //   messageWarn('Image size limit to 250KB');
      //   return;
      // }

      const { path } = await uploadPhoto(
        e.target.files[0],
        STORAGE_PATH.EDITOR,
        () => {
          this.setState((state) => ({ forceUpdate: state.forceUpdate + 1 }));
          // this.forceUpdate();
        }
      );

      let link = storagePath(path);
      const { name, form } = this.props;

      const audio = `<p class="auto-element">${encode(
        `<audio controls  class='cus-audio'><source src="${link}" type="audio/ogg"></audio>`
      )}</p>`;
      await sleep(2000);
      this.setState({ content: `${this.state.content}${audio}` });
      form.setFieldsValue({ [name]: `${this.state.content}${audio}` });
    }
  };

  // formatMeasureText = (text) => {
  //   const m = measureText(text) / 1024;
  //   if (m > 1024) return (m / 1024).toFixed(2) + 'MB';
  //   return m.toFixed(2) + 'KB';
  // }

  renderEditor = () => {
    let key = 'xs' + this.state.forceUpdate;
    if (!this.state.isDone && this.props.value) {
      key = this.state.content?.length;
      this.setState({ isDone: true });
    }

    return (
      <>
        <EditorContainer>
          <WrapIcon>
            <OptionIcon>
              <CloudUploadOutlined
                style={{ fontSize: '18px', marginRight: 12 ,marginTop: 32}}
                onClick={() => this.myRef.current.click()}
              />
              <AudioOutlined
                style={{ fontSize: '18px', marginRight: 12 ,marginTop: 32}}
                onClick={() => this.myRefAudio.current.click()}
              />
              <ZoomInOutlined
                style={{ fontSize: '18px' , marginTop: 32}}
                onClick={() => this.setState({ visible: true })}
              />
            </OptionIcon>
            {/* <MeasureText>
              {this.formatMeasureText(this.state.content)}
            </MeasureText> */}
          </WrapIcon>
          <CKEditor4
            key={key}
            config={config}
            data={this.state.content}
            onChange={this.onChangeValue}
          />
        </EditorContainer>
      </>
    );
  };

  componentDidUpdate = (prevProps) => {
    const { value } = this.props;
    if (value !== prevProps.value) {
      this.setState({ content: value });
    }
  };

  render() {
    const { label, required, tooltip, name, rules, fieldKey, className } =
      this.props;

    let tmpRules = [];
    if (rules) tmpRules = [...rules];

    if (measureText(this.state.content || '') / 1024 > 1 * 1024) {
      tmpRules = [
        ...tmpRules,
        { max: 1, message: 'Text can not bigger than 1MB' },
      ];
    }

    return (
      <>
        <input
          type='file'
          accept='image/*'
          ref={this.myRef}
          name='avatar'
          onChange={this.upload}
          className='d-none'
        />
        <input
          type='file'
          accept='.mp3,audio/*'
          ref={this.myRefAudio}
          name='avatar'
          onChange={this.uploadAudio}
          className='d-none'
        />
        <Form.Item
          name={name}
          rules={tmpRules}
          required={required}
          tooltip={tooltip}
          labelCol={{ span: 24 }}
          fieldKey={fieldKey}
          className={className}
        >
          <>
            {label && (
              <div className='ant-form-item-label'>
                <label
                  className={
                    required
                      ? 'ant-form-item-required ant-form-item-no-colon'
                      : ''
                  }
                  title={label}
                >
                  {label}
                </label>
              </div>
            )}
            {this.renderEditor()}
          </>
        </Form.Item>
        <Modal
          width='80%'
          title='Detail'
          visible={this.state.visible}
          onOk={() => this.setState({ visible: false })}
          onCancel={() => this.setState({ visible: false })}
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>{this.renderEditor()}</Col>
            <Col span={12}>
              <TextEditorPreview>
                <MathJaxPreview math={this.state.content} />
              </TextEditorPreview>
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}
