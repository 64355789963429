import { Modal, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Competency } from "../../../../atom/competency/competency.types";
import { Library } from "../../../../atom/library/library.types";
import { TableComponent } from "../../../../components/table/table.component";
import { useSelectOption } from "../../../../hooks/use-select-option.hook";
import { Meta } from "../../../../types/global.types";
import { showMessageErrors } from "../../../../utils/message.utils";
import {
  getCompetenciesAsync,
  getCompetencySelectCompetenciesAsync,
} from "../../../../atom/competency/competency.apis";
import {
  competenciesState,
  competencyLoadingState,
  competencyMetaState,
} from "../../../../atom/competency/competency.atoms";
import {
  competenciesSelector,
  competencyMetaSelector,
} from "../../../../atom/competency/competency.selectors";
import {
  canShowAll,
  convertObjectArrayToString,
  removeUndefined,
  toFilterSelect,
  toSort,
  toString,
  toStringFilter,
} from "../../../../utils/helper.utils";
import { useTopic } from "../../../../atom/topic/topic.hook";
import { SearchAndTitle } from "../../../../components/search-and-title/search-and-title.component";
import { useTranslation } from "react-i18next";
import { columnTableEllipsis } from "../../../../utils/table.utils";
import { ListFeatureOption } from "../../../../components/list-feature-option/list-feature-option.component";

const requestCompetencies = (
  query: any,
  setCompetencies: (items: Competency[]) => void,
  setMeta: (meta: Meta) => void,
  setLoading: (loading: boolean) => void
) => {
  const option = removeUndefined(query);
  setLoading(true);
  return getCompetenciesAsync({ ...option, status: true })
    .then(({ items, meta }) => {
      setCompetencies(items);
      setMeta(meta);
      setLoading(false);
    })
    .catch((err) => {
      showMessageErrors(err);
      setCompetencies([]);
      setMeta({});
      setLoading(false);
    });
};

interface CompetencyModalProps {
  visible: boolean;
  onOk: (value: any) => void;
  onCancel: () => void;
  defaultFilter: any;
}

export const CompetencyModal: React.FC<CompetencyModalProps> = ({
  visible,
  onOk,
  onCancel,
  defaultFilter,
}) => {
  const setCompetencies = useSetRecoilState(competenciesState);
  const setMeta = useSetRecoilState(competencyMetaState);
  const competencies = useRecoilValue(competenciesSelector);
  const meta = useRecoilValue(competencyMetaSelector);
  const [loading, setLoading] = useRecoilState(competencyLoadingState);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState<any>("");
  const [filteredInfo, setFilteredInfo] = useState<any>({});
  const [searchInfo, setSearchInfo] = useState<any>({});
  const [sortedInfo, setSortedInfo] = useState<any>({});
  const [columns, setColumns] = useState<any>([]);
  const { setTopicLevelId, setTopicSubjectId } = useTopic();
  const { topics, levels, subjects } = useSelectOption({
    hasTopic: true,
    hasLevel: true,
    hasSubject: true,
  });
  const { t } = useTranslation();
  const query: any = defaultFilter;
  useEffect(() => {
    /*eslint-disable react-hooks/exhaustive-deps*/
    if (visible) {
      const option = removeUndefined(query);
      requestCompetencies(
        { ...option, query: searchQuery },
        setCompetencies,
        setMeta,
        setLoading
      );
      setSortedInfo({ ...toSort(query) });
      setFilteredInfo({
        subjectId: toStringFilter(query.subjectId),
        levelId: toStringFilter(query.levelId),
        topicId: toStringFilter(query.topicId),
      });
      setTopicLevelId(query.levelId);
      setTopicSubjectId(query.subjectId);
    }
    return () => {
      setSearchInfo({});
    };
  }, [searchQuery, visible]);

  useEffect(() => {
    setColumns([
      {
        title: t("Code"),
        //fixed: 'left',
        dataIndex: "code",
        className: "drag-visible",
        isSearchable: true,
        sorter: (a: Competency, b: Competency) => a.code.length - b.code.length,
        sortOrder: sortedInfo.columnKey === "code" && sortedInfo.order,
        sortDirections: ["ascend", "descend"],
      },
      {
        title: t("Title"),
        //fixed: 'left',
        dataIndex: "title",
        isSearchable: true,
        sorter: (a: Competency, b: Competency) =>
          a.title.length - b.title.length,
        sortOrder: sortedInfo.columnKey === "title" && sortedInfo.order,
        sortDirections: ["ascend", "descend"],
      },
      {
        title: t("Subject"),
        dataIndex: "subjectId",
        filteredValue: filteredInfo.subjectId || null,
        filters: toFilterSelect(subjects),
        onFilter: () => true,
        ...columnTableEllipsis("subject"),
      },
      {
        title: t("Level"),
        dataIndex: "levelId",
        filteredValue: filteredInfo.levelId || null,
        filters: toFilterSelect(levels),
        onFilter: () => true,
        ...columnTableEllipsis("level"),
      },
      {
        title: t("Topic"),
        dataIndex: "topicId",
        filteredValue: filteredInfo.topicId || null,
        filters: toFilterSelect(topics),
        onFilter: () => true,
        ...columnTableEllipsis("topic"),
      },
      {
        title: t("Publish"),
        dataIndex: "status",
        filteredValue: filteredInfo.status || null,
        sortOrder: sortedInfo.columnKey === "status" && sortedInfo.order,
        render: (text: boolean, { id }: Competency) => (
          <Switch defaultChecked={text} size="small" />
        ),
      },
      {
        title: t("Updated At"),
        dataIndex: "updatedAt",
        sorter: (a: Competency, b: Competency) =>
          a.updatedAt.length - b.updatedAt.length,
        sortOrder: sortedInfo.columnKey === "updatedAt" && sortedInfo.updatedAt,
        sortDirections: ["descend", "ascend"],
      },
    ]);
  }, [filteredInfo, sortedInfo, subjects, levels, topics]);

  const onTableChange = (data: any) => {
    const {
      pagination: { current, pageSize },
      sortField,
      sortOrder,
      subjectId,
      levelId,
      topicId,
    } = data;

    const option = {
      ...query,
      ...searchInfo,
      page: current,
      offset: pageSize,
      sortField,
      sortOrder,
      query: searchQuery,
      subjectId: toString(subjectId),
      levelId: toString(levelId),
      topicId: toString(topicId),
    };

    setTopicLevelId(toString(levelId));
    setTopicSubjectId(toString(subjectId));
    setSortedInfo({ ...toSort({ sortField, sortOrder }) });
    setFilteredInfo({ subjectId, levelId, topicId });
    requestCompetencies(option, setCompetencies, setMeta, setLoading);
  };

  const onColumnSearch = (column: string, value: string) => {
    const option = removeUndefined({
      ...query,
      searchField: column,
      searchValue: value,
      query: searchQuery,
    });
    setSearchInfo({ searchField: column, searchValue: value });
    requestCompetencies(option, setCompetencies, setMeta, setLoading);
  };

  const onSelectedRows = (selectedRows: Competency[]) => {
    setSelectedRows(selectedRows);
  };

  const _onOk = () => {
    if (selectedRows.length) {
      getCompetencySelectCompetenciesAsync({
        ids: selectedRows.map((item: Library) => item.id),
      })
        .then((res) => {
          onOk(
            res.map((item: any) => {
              return {
                ...item,
                isNewRecord: true,
                competencyId: item.id,
                libraries: item.libraries.map((child: any) => {
                  return {
                    ...child,
                    libraryId: child.id,
                    children: child.children.map((child2: any) => {
                      return { ...child2, libraryId: child2.id };
                    }),
                  };
                }),
              };
            })
          );
        })
        .catch((err) => {
          showMessageErrors(err);
        });
    } else {
      onCancel();
    }
  };

  const handleSearch = (value: string) => {
    setSearchQuery(value);
  };

  const onShowAll = () => {
    const option = {
      ...convertObjectArrayToString(filteredInfo),
      ...searchInfo,
      query: searchQuery,
      showAll: "yes",
    };
    requestCompetencies(option, setCompetencies, setMeta, setLoading);
  };

  const onClearFilter = () => {
    setFilteredInfo({});
    setSortedInfo({});
    setSearchInfo({});
    requestCompetencies({}, setCompetencies, setMeta, setLoading);
  };

  return (
    <Modal
      visible={visible}
      title={null}
      closable={false}
      okText={t("Submit")}
      cancelText={t("Cancel")}
      onCancel={onCancel}
      maskClosable={false}
      width="80%"
      onOk={_onOk}
    >
      <SearchAndTitle title={t("Competencies")} handleSearch={handleSearch} />
      <ListFeatureOption
        onShowAll={onShowAll}
        onClearFilter={onClearFilter}
        showAll={canShowAll(searchInfo, filteredInfo)}
        query={query}
      />
      <TableComponent
        key={`table-${loading}-${competencies.length}`}
        dataSource={competencies}
        columns={columns}
        onChange={onTableChange}
        onColumnSearch={onColumnSearch}
        pagination={meta}
        loading={loading}
        query={query}
        hasSelectedRows={true}
        onSelectedRows={onSelectedRows}
      />
    </Modal>
  );
};
