import React, { useCallback, useState, Fragment } from "react";
import {
  Avatar,
  Dropdown,
  Menu,
  Radio,
  RadioChangeEvent,
  Drawer,
  Space,
  Switch,
} from "antd";
import { Link } from "react-router-dom";
import {
  FlagOutlined,
  KeyOutlined,
  LoginOutlined,
  UserOutlined,
  EditOutlined,
  NodeIndexOutlined,
} from "@ant-design/icons";
import {
  MainSidebar,
  Profile,
  MenuWrapper,
  Name,
  Role,
  Version,
  Container,
} from "./sidebar.styles";
import { menus } from "../../../constants/menus.const";
import useRoute from "../../../hooks/use-route.hook";
import { useUserProfileVisible } from "../../../hooks/profile-visible.hook";
import _ from "lodash";
import { RoutePaths } from "../../../constants/route.const";
import { LNG } from "../../../constants/default-value.const";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../hooks/use-auth";
import { checkPermission, isAdmin } from "../../../utils/helper.utils";
import { UserProfileComponent } from "../../../pages/manages/profile/user-profile.component";
import { ChangePasswordComponent } from "../../../pages/manages/change-password/change-password.component";
import { storagePath } from "../../../utils/firebase.utils";
import { drawerMenuVisibleState } from "../../../atom/app/app.atoms";
import { useApp } from "../../../atom/app/app.hook";
import { useRecoilState } from "recoil";
import { clearAppLocalStorage } from "../../../utils/message.utils";

const switchKey = (pathname: string) => {
  if (pathname.includes(RoutePaths.CONTENT_CATEGORIES))
    return RoutePaths.CONTENT_CATEGORIES;
  else if (pathname.includes(RoutePaths.CATEGORY_TYPES))
    return RoutePaths.CATEGORY_TYPES;
  else if (pathname.includes(RoutePaths.COLOR_AND_PLACEHOLDERS))
    return RoutePaths.COLOR_AND_PLACEHOLDERS;
  else if (pathname.includes(RoutePaths.USER_ROLES))
    return RoutePaths.USER_ROLES;
  else if (pathname.includes("/elements/questions"))
    return "/elements/questions";
  else if (pathname.includes("/form"))
    return _.get(pathname.split("/form"), "0", "");

  return "";
};

export const Sidebar = () => {
  let { pathname, history } = useRoute();
  let { profile } = useAuth({ hasProfile: true });
  const { appOwnContent, setAppOwnContent } = useApp();
  const [lng, setLng] = useState<string>(localStorage.getItem("lng") || LNG.EN);
  const { t, i18n } = useTranslation();
  const { visible, setVisible } = useUserProfileVisible();
  const [visibleChange, setVisibleChange] = useState<boolean>(false);
  const [drawerMenuVisible, setDrawerMenuVisible] = useRecoilState(
    drawerMenuVisibleState
  );

  const getOpenKeys = useCallback((): string => {
    let paths = _.trim(pathname, "/").split("/");
    if (_.get(paths, "0")) {
      return _.get(paths, "0");
    }
    return "";
  }, [pathname]);

  const getSelectedKey = useCallback(() => {
    if (switchKey(pathname)) return switchKey(pathname);

    let paths = _.trim(pathname, "/").split("/");
    if (paths.length === 3) {
      paths.pop();
      return paths.join("/");
    }
    return pathname;
  }, [pathname]);

  const logout = () => {
    clearAppLocalStorage();
    history.push(RoutePaths.SIGN_IN);
  };

  const onChange = (value: RadioChangeEvent) => {
    const _lng = value.target.value;
    setLng(_lng);
    localStorage.setItem("lng", _lng);
    i18n.changeLanguage(_lng);
  };

  const onChangeOwnContent = (value: boolean) => {
    localStorage.setItem("ownContent", value + "");
    setAppOwnContent(value);
  };

  // Profile dropdown menu

  const menu = (
    <Menu>
      <Menu.Item key="change-password" onClick={() => setVisibleChange(true)}>
        <KeyOutlined style={{ marginRight: "10px" }} />
        {t("Change password")}
      </Menu.Item>
      <Menu.Item key="edit-profile" onClick={() => setVisible(true)}>
        <EditOutlined style={{ marginRight: "10px" }} />
        {t("Edit Profile")}
      </Menu.Item>
      <Menu.Item key="own-content">
        <Space>
          <NodeIndexOutlined style={{ marginRight: "10px" }} />
          {t("My Content")}
          <Switch
            checked={appOwnContent ? true : false}
            onChange={onChangeOwnContent}
          />
        </Space>
      </Menu.Item>
      <Menu.Item>
        <FlagOutlined style={{ marginRight: "10px" }} />
        <Radio.Group onChange={onChange} value={lng}>
          <Radio value={LNG.EN}>EN</Radio>
          <Radio value={LNG.KM}>ខ្មែរ</Radio>
        </Radio.Group>
      </Menu.Item>
      <Menu.Item onClick={logout}>
        <LoginOutlined style={{ marginRight: "10px" }} />
        {t("Logout")}
      </Menu.Item>
    </Menu>
  );

  // User profile block

  const navbar = (
    <MainSidebar width="238" theme="light" trigger={null}>
      {/* <Logo>TESDOPI-CONTENT</Logo> */}
      <Dropdown overlay={menu}>
        <Profile>
          <Avatar
            src={storagePath(_.get(profile, "profile"))}
            size={60}
            icon={<UserOutlined />}
          />
          <span>
            <Name>{_.get(profile, "fullName")}</Name> <br />
            <Role>Role : {_.get(profile, "roleName")}</Role> <br />
            <Version>Verion : 2.1.2</Version>
          </span>
        </Profile>
      </Dropdown>

      <MenuWrapper>
        <Menu
          mode="inline"
          defaultSelectedKeys={["/"]}
          selectedKeys={[getSelectedKey()]}
          defaultOpenKeys={[getOpenKeys()]}
        >
          {menus.map((menu) => {
            const { subMenus, key, title, icon, slug } = menu;
            return subMenus ? (
              <Menu.SubMenu key={key.substr(1)} icon={icon} title={t(title)}>
                {subMenus.map((subMenu: any) => {
                  const { key, title, icon, slug, prefixPermission } = subMenu;
                  return checkPermission(profile, prefixPermission).list ||
                    isAdmin(profile) ? (
                    <Menu.Item key={key} icon={icon}>
                      <Link to={`${slug}`}>{t(title)}</Link>
                    </Menu.Item>
                  ) : null;
                })}
              </Menu.SubMenu>
            ) : (
              <Menu.Item key={key} icon={icon}>
                <Link to={`${slug}`}>{t(title)}</Link>
              </Menu.Item>
            );
          })}
        </Menu>
      </MenuWrapper>
    </MainSidebar>
  );

  return (
    <Fragment>
      {visible && (
        <UserProfileComponent visible={visible} setVisible={setVisible} />
      )}
      <ChangePasswordComponent
        visible={visibleChange}
        setVisible={setVisibleChange}
      />
      {/* Desktop */}
      <Container className="desktop">{navbar}</Container>
      {/* Mobile */}
      <Container className="mobile">
        <Drawer
          placement="left"
          onClose={() => setDrawerMenuVisible(false)}
          visible={drawerMenuVisible}
          closeIcon={false}
          closable={true}
        >
          {navbar}
        </Drawer>
      </Container>
    </Fragment>
  );
};
